import './App.css';
import Board from './Pages/Main';

function App() {
  return (
    <div>
        <Board />
    </div>
  );
}

export default App;
